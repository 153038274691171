import React, { useRef, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { Card, Typography, Box, CardActionArea } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CardMedia from "@mui/material/CardMedia";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { SearchDeckTabContext } from "../SearchDeckTab/SearchDeckTabContext";
import LaunchIcon from "@mui/icons-material/Launch";

function AceCardDisplay({
  aceCards,
  setTabValue,
  mainDeckType,
  subDeckType,
  setMainDeckType,
  setSubDeckType,
  setCardNameQueryList,
}) {
  const imageRefs = useRef([]);

  useEffect(() => {
    aceCards.forEach((_, idx) => {
      const img = imageRefs.current[idx];
      if (img) {
        // const imageHeight = img.naturalHeight;
        const imageHeight = img.height;

        const box = img.parentNode; // 画像の親要素を取得
        box.style.height = `${imageHeight * 0.47}px`;
      }
    });
  }, [aceCards]);

  const CustomLabel = (props) => {
    const { x, y, height, value, name } = props;
    const width = 70;
    return (
      <foreignObject
        x={x - width - 5}
        y={y - 12}
        width={width}
        height={height}
        style={{ overflow: "visible" }}
      >
        <Card sx={{ border: "1px solid #bdbdbd" }}>
          <CardActionArea
            onClick={() => {
              if (name !== "エースカードなし") {
                setMainDeckType(mainDeckType);
                setSubDeckType(subDeckType);
                setCardNameQueryList([
                  {
                    card_names: [`${name}(ACE SPEC)`],
                    card_num: 1,
                    condition: "ちょうど",
                  },
                ]);
                setTabValue("4");
                setTabValue("4");
              }
            }}
          >
            <Box position="relative">
              <CardMedia
                component="img"
                image={value}
                style={{
                  transform: "translateY(-15%)",
                  aspectRatio: "1.35/1",
                  objectPosition: "top",
                  marginBottom: "-15%",
                }}
                referrerPolicy="no-referrer"
              />
              <Box
                position="absolute"
                top={0}
                right={0}
                sx={{
                  p: "1px", // Padding inside the box for some spacing from the edges
                }}
              >
                <LaunchIcon sx={{ fontSize: 10, color: "white" }} />
              </Box>
            </Box>
          </CardActionArea>
        </Card>
      </foreignObject>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={aceCards.length * 70 + 20}>
      <BarChart
        data={aceCards}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        layout="vertical"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          tickFormatter={(value) => `${value}%`}
          domain={[0, 100]}
        />
        <YAxis type="category" dataKey="name" tick={false} width={70} />
        <Bar
          dataKey="share"
          fill="#303f9f"
          label="false"
          barSize={20}
          onClick={(data, index) => {
            if (aceCards[index].name !== "エースカードなし") {
              setMainDeckType(mainDeckType);
              setSubDeckType(subDeckType);
              setCardNameQueryList([
                {
                  card_names: [`${aceCards[index].name}(ACE SPEC)`],
                  card_num: 1,
                  condition: "ちょうど",
                },
              ]);
              setTabValue("4");
            }
          }}
          onMouseOver={(data, index) => {}}
        >
          <LabelList
            dataKey="thumbnail"
            content={<CustomLabel />}
            position="left"
          />
          <LabelList
            dataKey="share"
            position="right"
            formatter={(value) => `${value}%`}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default function AceCardTab({ items, loading, error, setTabValue }) {
  const { setMainDeckType, setSubDeckType, setCardNameQueryList } =
    useContext(SearchDeckTabContext);

  if (error) {
    return (
      <Typography variant="body1" color="error" marginY={2}>
        {error}
      </Typography>
    );
  }

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (items.length === 0) {
    return <p>No data found.</p>;
  }

  return (
    <Box>
      {items.map((item, index) => (
        <Box key={index} marginBottom={5}>
          <Typography
            variant="h6"
            style={{
              backgroundColor: "#f5f5f5",
              paddingLeft: "8px",
              borderLeft: `5px solid #FE6B8B`,
            }}
            marginBottom={2}
          >
            {item.sub_deck_type}
          </Typography>
          <Typography variant="body1" textAlign="right" color="#263238">
            デッキ数: {item.result_num}
          </Typography>
          <Grid container spacing={1}>
            <AceCardDisplay
              aceCards={item.ace_cards}
              setTabValue={setTabValue}
              mainDeckType={item.main_deck_type}
              subDeckType={item.sub_deck_type}
              setMainDeckType={setMainDeckType}
              setSubDeckType={setSubDeckType}
              setCardNameQueryList={setCardNameQueryList}
            />
          </Grid>
        </Box>
      ))}
    </Box>
  );
}
