import React, { useContext, useEffect, useState } from "react";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SearchDeckTabContext } from "./SearchDeckTabContext";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { FormControlLabel } from "@mui/material";
import CardNameForm from "./CardNameForm";

function DisplayCategory({ item }) {
  const itemsPerPage = 3;
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(item.decks.length / itemsPerPage);

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDecks = item.decks.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{
            paddingLeft: "8px",
            borderLeft: `5px solid #FE6B8B`,
            fontWeight: "bold",
          }}
        >
          {item.category}
        </Typography>
      </Grid>
      {currentDecks.map((deck, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Card elevation={3} sx={{ border: "1px solid #bdbdbd" }}>
            <Box position="relative">
              <Link
                to={deck.deck_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardMedia
                  component="img"
                  image={deck.thumbnail}
                  referrerPolicy="no-referrer"
                />
              </Link>
              <Box
                position="absolute"
                top={0}
                right={0}
                sx={{
                  p: "4px", // Padding inside the box for some spacing from the edges
                }}
              >
                <LaunchIcon sx={{ fontSize: 20, color: "grey" }} />
              </Box>
            </Box>
            <CardContent
              style={{
                padding: "8px 8px 4px 8px",
              }}
            >
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {deck.deck_type} <br />
                    <Link
                      to={deck.deck_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      デッキ詳細
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" align="right">
                    {deck.date} <br />
                    {item.category} <br />
                    <Link
                      to={deck.event_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      イベント詳細
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => {
            setPage(value);
          }}
        />
      </Grid>
    </>
  );
}

export default function SearchDeckTab({ startDate, endDate, dateRangeError }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    mainDeckType,
    setMainDeckType,
    subDeckType,
    setSubDeckType,
    items,
    setItems,
    cardNameQueryList,
    setCardNameQueryList,
  } = useContext(SearchDeckTabContext);
  const [mainDeckTypeOptions, setMainDeckTypeOptions] = useState([]);
  const [subDeckTypeOptions, setSubDeckTypeOptions] = useState([]);

  const handleUpdateQuery = (index, key, value) => {
    const newQueryList = [...cardNameQueryList];
    newQueryList[index][key] = value;
    setCardNameQueryList(newQueryList);
  };

  const handleDeleteQuery = (index) => {
    const newQueryList = cardNameQueryList.filter((_, i) => i !== index);
    if (newQueryList.length === 0) {
      newQueryList.push({ card_names: [], card_num: 1, condition: "以上" });
    }
    setCardNameQueryList(newQueryList);
  };

  const customFetch = async () => {
    let url = `${process.env.REACT_APP_API_URL}/search_deck`;
    const params = new URLSearchParams();

    if (startDate) params.append("start_date", startDate.toISOString());
    if (endDate) params.append("end_date", endDate.toISOString());
    if (mainDeckType && mainDeckType !== "指定なし")
      params.append("main_deck_type", mainDeckType);
    if (subDeckType && subDeckType !== "指定なし")
      params.append("sub_deck_type", subDeckType);
    params.append("card_name_query_list", JSON.stringify(cardNameQueryList));

    url = url + (params.toString() ? `?${params}` : "");

    const response = await fetch(url);
    if (!response.ok) {
      const errorBody = await response.json();
      setError(
        "ERROR: " + errorBody.detail || `API returned status ${response.status}`
      );
      setItems({
        stats: {
          earliest_date: "",
          latest_date: "",
          event_num: 0,
          result_num: 0,
        },
        data: [],
      });
    } else {
      setError(null);
      const data = await response.json();
      setItems(data);
    }
  };

  const fetchData = async () => {
    if (dateRangeError) return;

    setLoading(true);

    try {
      await customFetch("search_deck", setItems, setError);
    } catch (e) {
      console.error("An unexpected error occured: ", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, dateRangeError]);

  useEffect(() => {
    const fetchMainDeckType = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/main_deck_type`
      );
      if (response.ok) {
        const data = await response.json();
        setMainDeckTypeOptions(data);
      }
    };

    fetchMainDeckType();
  }, []);

  useEffect(() => {
    const fetchSubDeckType = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sub_deck_type/?main_deck_type=${mainDeckType}`
      );
      if (response.ok) {
        const data = await response.json();
        setSubDeckTypeOptions(data);
      }
    };

    if (mainDeckType) {
      fetchSubDeckType();
    }
  }, [mainDeckType]);

  let content;
  if (error) {
    content = (
      <Typography varient="body1" color={"error"} marginY={2}>
        {error}
      </Typography>
    );
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }} width="100%">
        <CircularProgress />
      </Box>
    );
  } else {
    content = (
      <Grid container spacing={2}>
        {items.data.map((item, index) => (
          <DisplayCategory item={item} key={index} />
        ))}
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 1 }}>
        <Grid item xs={12} sm={4}>
          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Select
                    labelId="select-label"
                    value={mainDeckType}
                    onChange={(event) => {
                      setMainDeckType(event.target.value);
                      setSubDeckType("指定なし");
                    }}
                    size="small"
                    fullWidth
                  >
                    <MenuItem
                      value={"指定なし"}
                      sx={{ backgroundColor: "#eeeeee" }}
                    >
                      指定なし
                    </MenuItem>
                    {mainDeckTypeOptions.map((option) => (
                      <MenuItem value={option["name"]} key={option["name"]}>
                        {option["name"]}
                      </MenuItem>
                    ))}
                  </Select>
                }
                label={
                  <Typography
                    variant="caption"
                    marginRight={1}
                    minWidth="120px"
                  >
                    メインデッキタイプ
                  </Typography>
                }
                labelPlacement="start"
                sx={{ marginLeft: 0, marginRight: 0, width: "100%" }}
              />
            </Grid>
            {subDeckTypeOptions.length > 1 && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Select
                      labelId="select-label"
                      value={subDeckType}
                      onChange={(event) => {
                        setSubDeckType(event.target.value);
                      }}
                      size="small"
                      fullWidth
                    >
                      <MenuItem
                        value={"指定なし"}
                        sx={{ backgroundColor: "#eeeeee" }}
                      >
                        指定なし
                      </MenuItem>
                      {subDeckTypeOptions.map((option) => (
                        <MenuItem value={option["name"]} key={option["name"]}>
                          {option["name"]}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  label={
                    <Typography
                      variant="caption"
                      marginRight={1}
                      minWidth="120px"
                    >
                      サブデッキタイプ
                    </Typography>
                  }
                  labelPlacement="start"
                  sx={{ marginLeft: 0, marginRight: 0, width: "100%" }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CardNameForm
                queryList={cardNameQueryList}
                setQueryList={setCardNameQueryList}
                handleUpdateQuery={handleUpdateQuery}
                handleDeleteQuery={handleDeleteQuery}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} />
      </Grid>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ marginBottom: 2 }}
        onClick={() => {
          fetchData();
        }}
      >
        検索
      </Button>

      {content}
    </>
  );
}
