import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { SearchDeckTabContext } from "../SearchDeckTab/SearchDeckTabContext";

export default function TableComponent({
  mainDeckType,
  subDeckType,
  items,
  setTabValue,
  currentCardName,
}) {
  const { setMainDeckType, setSubDeckType, setCardNameQueryList } =
    useContext(SearchDeckTabContext);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>使用枚数</TableCell>
            <TableCell>採用率</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items.map((row, index) => (
            <TableRow
              key={row.type}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Link
                  onClick={() => {
                    let card_num = row.label
                      .replace("枚", "")
                      .replace("以上", "");
                    if (card_num !== "0") {
                      setMainDeckType(mainDeckType);
                      setSubDeckType(subDeckType);
                      setCardNameQueryList([
                        {
                          card_names: [currentCardName],
                          card_num: card_num,
                          condition: "以上",
                        },
                      ]);
                      setTabValue("4");
                    }
                  }}
                >
                  {row.label}
                </Link>
              </TableCell>
              <TableCell>{row.rate}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
