import React, { useEffect, useRef, useState } from "react";
import TableComponent from "./TableComponent";
import { Typography, alpha } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { blueGrey } from "@mui/material/colors";
import CustomLabel from "./CustomLabelComponent";

export default function DeckListTab({ items, loading, error, setTabValue }) {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth);
        setChartHeight(chartWidth * 0.5);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [loading, chartWidth]);

  let content;
  if (error) {
    content = (
      <Typography variant="body1" color={"error"} marginY={2}>
        {error}
      </Typography>
    );
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (items.length === 0) {
    content = <p>No data found.</p>;
  } else {
    const colors = [
      blueGrey[700],
      blueGrey[600],
      blueGrey[500],
      blueGrey[400],
      blueGrey[300],
    ];

    content = (
      <>
        {/* 本当はこの部分を別ファイルに分離したかったけど最初の画面ロード時にアニメーションが実行されない問題があり諦め */}
        <Paper elevation={3}>
          <Grid container justifyContent="center" marginBottom={2}>
            <Grid item ref={chartContainerRef} xs={12} sm={6}>
              <ResponsiveContainer width="100%" height={chartHeight}>
                <PieChart width="100%" height={chartHeight}>
                  <Pie
                    data={items}
                    startAngle={90}
                    endAngle={-270}
                    innerRadius="40%"
                    outerRadius="80%"
                    dataKey="top16_share"
                    nameKey="type"
                    label={CustomLabel}
                    labelLine={false}
                    animationDuration={700}
                  >
                    {items.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={(() => {
                          if (index < colors.length) {
                            return colors[index % colors.length];
                          } else {
                            const alphaValue =
                              1 -
                              (index + 1 - colors.length) /
                                (items.length - colors.length);
                            return alpha(colors[colors.length - 1], alphaValue);
                          }
                        })()}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </Paper>

        <TableComponent items={items} setTabValue={setTabValue} />
      </>
    );
  }

  return <div>{content}</div>;
}
