import React from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  List,
  ListItem,
  IconButton,
  Divider,
  Autocomplete,
  Select,
  MenuItem,
  ListItemText,
  Paper,
  ListItemSecondaryAction,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Link from "@mui/material/Link";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

const DeckModal = ({
  open,
  onClose,
  onSubmit,
  formState,
  setFormState,
  cardNameOptions,
  setCurrentCardName,
  title,
}) => {
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => {
      const errors = { ...prevState.errors };
      if (
        name === "name" &&
        (value === null || value === undefined || value.trim() === "")
      ) {
        errors.name = "デッキ名を入力してください";
      } else {
        errors.name = "";
      }
      return { ...prevState, [name]: value, errors };
    });
  };

  const handleConditionChange = (index, name, value) => {
    setFormState((prevState) => {
      const conditions = [...prevState.conditions];
      conditions[index][name] = value;
      const errors = { ...prevState.errors };
      if (
        name === "card_name" &&
        (value === null || value === undefined || value.trim() === "")
      ) {
        errors.conditions[index] = "カード名を入力してください";
      } else {
        errors.conditions[index] = "";
      }
      return { ...prevState, conditions, errors };
    });
  };

  const addCondition = () => {
    setFormState((prevState) => ({
      ...prevState,
      conditions: [...prevState.conditions, { card_name: "", num: 1 }],
    }));
  };

  const removeCondition = (index) => {
    setFormState((prevState) => ({
      ...prevState,
      conditions: prevState.conditions.filter((_, i) => i !== index),
    }));
  };

  const handleThumbnailChange = (index, value) => {
    setFormState((prevState) => {
      const thumbnails = [...prevState.thumbnails];
      thumbnails[index] = value;
      return { ...prevState, thumbnails };
    });
  };

  const addThumbnail = () => {
    setFormState((prevState) => ({
      ...prevState,
      thumbnails: [...prevState.thumbnails, ""],
    }));
  };

  const removeThumbnail = (index) => {
    setFormState((prevState) => ({
      ...prevState,
      thumbnails: prevState.thumbnails.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    let errors = { name: "", conditions: [] };
    let isValid = true;

    if (!formState.name) {
      errors.name = "デッキ名を入力してください";
      isValid = false;
    }

    formState.conditions.forEach((condition, index) => {
      if (!condition.card_name) {
        errors.conditions[index] = "カード名を入力してください";
        isValid = false;
      } else {
        errors.conditions[index] = "";
      }
    });

    setFormState((prevState) => ({ ...prevState, errors }));

    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "70%", sm: "70%", md: 400 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          label="デッキ名"
          name="name"
          variant="standard"
          value={formState.name}
          onChange={handleFormChange}
          error={!!formState.errors.name}
          helperText={formState.errors.name}
        />
        <Typography variant="body1" component="div" mt={2}>
          カード条件
        </Typography>
        <Typography variant="body2" component="div" color="grey">
          カード名：枚数（以上）
        </Typography>
        <List dense disablePadding>
          {formState.conditions.map((condition, index) => (
            <React.Fragment key={index}>
              <ListItem
                alignItems="center"
                sx={{ paddingLeft: 0, paddingRight: 4 }}
              >
                <Autocomplete
                  options={cardNameOptions.map((option) => option.name)}
                  renderInput={(params) => (
                    <TextField
                      margin="none"
                      {...params}
                      variant="standard"
                      size="small"
                      sx={{ mr: 2, flexGrow: 1, minWidth: 180 }}
                      error={!!formState.errors.conditions[index]}
                      helperText={formState.errors.conditions[index]}
                    />
                  )}
                  name="card_name"
                  onInputChange={(event, newValue) =>
                    setCurrentCardName(newValue)
                  }
                  value={condition.card_name}
                  onChange={(event, newValue) =>
                    handleConditionChange(index, "card_name", newValue)
                  }
                />
                <Typography variant="body2" component="span">
                  ：
                </Typography>
                <Select
                  margin="none"
                  name="num"
                  variant="standard"
                  value={condition.num || 1}
                  onChange={(e) =>
                    handleConditionChange(index, e.target.name, e.target.value)
                  }
                  size="small"
                  defaultValue={1}
                >
                  {[1, 2, 3, 4].map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => removeCondition(index)}
                    size="small"
                    edge="end"
                    sx={{ marginRight: -3 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {index < formState.conditions.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
        <IconButton onClick={addCondition} size="small" variant="contained">
          <AddCircleRoundedIcon sx={{ color: "#00897b" }} />
        </IconButton>
        <Typography variant="body1" component="div" mt={2}>
          サムネイルURL
        </Typography>
        <List dense disablePadding>
          {formState.thumbnails &&
            formState.thumbnails.map((thumbnail, index) => (
              <React.Fragment key={index}>
                <ListItem
                  alignItems="center"
                  sx={{ paddingLeft: 0, paddingRight: 4 }}
                >
                  <TextField
                    margin="none"
                    variant="standard"
                    fullWidth
                    value={thumbnail}
                    onChange={(e) =>
                      handleThumbnailChange(index, e.target.value)
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => removeThumbnail(index)}
                      size="small"
                      edge="end"
                      sx={{ marginRight: -3 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {index < formState.thumbnails.length - 1 && <Divider />}
              </React.Fragment>
            ))}
        </List>
        <IconButton onClick={addThumbnail} size="small" variant="contained">
          <AddCircleRoundedIcon sx={{ color: "#00897b" }} />
        </IconButton>
        <Paper
          variant="outlined"
          sx={{ backgroundColor: "#f5f5f5", padding: 1 }}
        >
          <List dense disablePadding>
            {formState.conditions.map((condition, index) => (
              <React.Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemText>
                    <Link
                      href={`https://www.pokemon-card.com/card-search/index.php?keyword=${condition.card_name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {condition.card_name}のカード検索結果
                    </Link>
                  </ListItemText>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "150px" }}
          >
            保存
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeckModal;
