import React from "react";
import Header from "./Header";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Toolbar } from "@mui/material";
import Container from "@mui/material/Container";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: { main: "#FE6B8B" },
    secondary: { main: "#FF8E53" },
    background: { default: "#fff" },
  },
});

const Layout = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize("G-RJ2C336DGR");
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Box
        component="main"
        sx={{ flexGrow: 1, height: "100vh", overflow: "auto" }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ marginTop: 4 }}>
          {children}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
