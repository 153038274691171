import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const StyledAppBar = styled(AppBar)({
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
});

export default function Header() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledAppBar position="absolute">
        <Toolbar>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              PTCG-DataBase
            </Typography>
          </Link>
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
}
