import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

dayjs.extend(utc);

export default function DateAndStats({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateRangeError,
  setDateRangeError,
  stats,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const formatDateString = (dateStr) => {
    if (dateStr) {
      const [year, month, day] = dateStr.split("-");
      return { year, date: `${month}-${day}` };
    } else {
      return { year: "", date: "" };
    }
  };
  const formattedEarliestDate = formatDateString(stats.earliest_date);
  const formattedLatestDate = formatDateString(stats.latest_date);

  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={6} sm={3}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onAccept={(newValue) => {
                setStartDate(newValue);
                if (endDate && dayjs(endDate).isBefore(dayjs(newValue))) {
                  setDateRangeError("終了日は開始日よりも後に設定してください");
                } else {
                  setDateRangeError("");
                }
              }}
              timezone="UTC"
              slotProps={{
                field: { clearable: true },
                textField:
                  isMobile && startDate
                    ? {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setStartDate(null);
                                  setDateRangeError("");
                                }}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                      }
                    : {},
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DatePicker
              label="End Date"
              value={endDate}
              onAccept={(newValue) => {
                setEndDate(newValue);
                if (startDate && dayjs(newValue).isBefore(dayjs(startDate))) {
                  setDateRangeError("終了日は開始日よりも後に設定してください");
                } else {
                  setDateRangeError("");
                }
              }}
              timezone="UTC"
              slotProps={{
                field: { clearable: true },
                textField:
                  isMobile && endDate
                    ? {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEndDate(null);
                                  setDateRangeError("");
                                }}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                      }
                    : {},
              }}
            />
          </Grid>
          {dateRangeError && (
            <Grid item xs={12}>
              <Typography color="error">{dateRangeError}</Typography>
            </Grid>
          )}
        </LocalizationProvider>
      </Grid>

      <Box variant="body2">
        <Grid container marginTop={2} columnSpacing={2}>
          <Grid item xs={4}>
            <Stack direction="column">
              <Box
                sx={{ borderBottom: "1px solid black" }}
                textAlign="center"
                paddingBottom={0.5}
                color={"grey"}
              >
                期間
              </Box>
              <Stack direction="row" justifyContent="center">
                <Stack direction="column">
                  <Typography variant="caption" marginBottom={-0.7}>
                    {formattedEarliestDate.year}
                  </Typography>
                  <Box>{formattedEarliestDate.date} ～ </Box>
                </Stack>
                <Stack direction="column" marginLeft={0.5}>
                  <Typography variant="caption" marginBottom={-0.7}>
                    {formattedLatestDate.year}
                  </Typography>
                  <Box>{formattedLatestDate.date}</Box>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column">
              <Box
                sx={{ borderBottom: "1px solid black" }}
                textAlign="center"
                paddingBottom={0.5}
                color="grey"
              >
                イベント数
              </Box>
              <Box component="span" textAlign="center" marginTop={1}>
                {stats.event_num}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column">
              <Box
                sx={{ borderBottom: "1px solid black" }}
                textAlign="center"
                paddingBottom={0.5}
                color="grey"
              >
                デッキ数
              </Box>
              <Box component="span" textAlign="center" marginTop={1}>
                {stats.result_num}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
