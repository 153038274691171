import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  List,
  ListItem,
  Divider,
  Button,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ListItemText } from "@mui/material";

const DeckCard = ({ deck, openEditModal, openDeleteDialog, openAddModal }) => (
  <Card variant="outlined" sx={{ boxShadow: 3 }}>
    <CardContent>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          {deck.thumbnails &&
            deck.thumbnails.map((thumbnail, idx) => (
              <CardMedia
                component="img"
                image={thumbnail}
                alt={deck.name}
                sx={{ width: 80, height: 80, mr: 2, objectFit: "contain" }}
              />
            ))}
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h5" component="div">
              {deck.name}
            </Typography>
            <Box>
              <Stack>
                {(deck.conditions || []).map((condition, index) => (
                  <Typography key={index} variant="body2" component="span">
                    {condition.card_name} {condition.num}枚以上
                  </Typography>
                ))}
              </Stack>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <IconButton onClick={() => openEditModal(deck)} size="small">
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => openDeleteDialog(deck)}
            size="small"
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      {deck.sub_deck_types && deck.sub_deck_types.length > 0 && (
        <Box mt={2}>
          <Typography variant="h6" component="div">
            サブタイプ
          </Typography>
          <List dense disablePadding>
            {deck.sub_deck_types.map((subDeck, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  {subDeck.thumbnails &&
                    subDeck.thumbnails.map((thumbnail, idx) => (
                      <CardMedia
                        component="img"
                        image={thumbnail}
                        alt={subDeck.name}
                        sx={{
                          width: 60,
                          height: 60,
                          mr: 2,
                          objectFit: "contain",
                        }}
                      />
                    ))}
                  <ListItemText
                    primary={subDeck.name}
                    secondary={
                      <Stack component="span">
                        {subDeck.conditions.map((condition, idx) => (
                          <Typography
                            key={idx}
                            variant="body2"
                            component="span"
                          >
                            {condition.card_name} {condition.num}枚以上
                          </Typography>
                        ))}
                      </Stack>
                    }
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    ml="auto"
                  >
                    <IconButton
                      onClick={() => openEditModal(deck, subDeck)}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => openDeleteDialog(subDeck)}
                      size="small"
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </ListItem>
                {index < deck.sub_deck_types.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Box>
      )}
      <Button
        variant="outlined"
        onClick={() => openAddModal(deck.id)}
        startIcon={<AddIcon />}
        sx={{ marginTop: 2 }}
      >
        サブタイプを追加
      </Button>
    </CardContent>
  </Card>
);

export default DeckCard;
