import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import DeckListTab from "./DeckListTab/DeckListTab";
import AceCardTab from "./AceCardTab/AceCardTab";
import CardUsageRateTab from "./CardUsageRateTab/CardUsageRateTab";
import Tab from "@mui/material/Tab";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import Layout from "../../components/Layout";
import DateAndStats from "../../components/DateAndStats";
import SearchDeckTab from "./SearchDeckTab/SearchDeckTab";
import SearchDeckTabProvider from "./SearchDeckTab/SearchDeckTabProvider";

export default function HomePage() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRangeError, setDateRangeError] = useState("");
  const [cardName, setCardName] = useState("");
  const [currentCardName, setCurrentCardName] = useState("");
  const [deckListData, setDeckListData] = useState({ stats: {}, data: [] });
  const [deckListError, setDeckListError] = useState(null);
  const [aceCardData, setAceCardData] = useState({ stats: {}, data: [] });
  const [aceCardError, setAceCardError] = useState(null);
  const [cardUsageRateData, setCardUsageRateData] = useState({
    stats: {},
    data: [],
  });
  const [cardUsageRateError, setCardUsageRateError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("1");

  const customFetch = async (endpoint, setData, setError) => {
    let url = `${process.env.REACT_APP_API_URL}/${endpoint}`;
    const params = new URLSearchParams();
    if (startDate) params.append("start_date", startDate.toISOString());
    if (endDate) params.append("end_date", endDate.toISOString());
    if (cardName) params.append("card_name", cardName);
    url = url + (params.toString() ? `?${params}` : "");

    const response = await fetch(url);
    if (!response.ok) {
      const errorBody = await response.json();
      setError(
        "ERROR: " + errorBody.detail || `API returned status ${response.status}`
      );
      setData({
        stats: {
          earliest_date: "",
          latest_date: "",
          event_num: 0,
          result_num: 0,
        },
        data: [],
      });
    } else {
      setError(null);
      const data = await response.json();
      setData(data);
    }
  };

  useEffect(() => {
    if (dateRangeError) return;

    setLoading(true);

    const fetchAllData = async () => {
      try {
        await customFetch("deck_list", setDeckListData, setDeckListError);
        await customFetch("ace_card", setAceCardData, setAceCardError);
        if (cardName) {
          await customFetch(
            "card_usage_rate",
            setCardUsageRateData,
            setCardUsageRateError
          );
        }
      } catch (e) {
        // ネットワークエラーなどの問題
        console.error("An unexpected error occurred:", e);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, dateRangeError]);

  const fetchByCardName = async () => {
    setLoading(true);

    try {
      await customFetch(
        "card_usage_rate",
        setCardUsageRateData,
        setCardUsageRateError
      );
    } catch (e) {
      // ネットワークエラーなどの問題
      console.error("An unexpected error occurred:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SearchDeckTabProvider>
      <Layout>
        <DateAndStats
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          dateRangeError={dateRangeError}
          setDateRangeError={setDateRangeError}
          stats={deckListData.stats}
        />

        <Box sx={{ width: "100%", typography: "body1", marginTop: 1 }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                variant="scrollable"
              >
                <Tab
                  label="デッキリスト"
                  value="1"
                  style={{
                    fontWeight: "bold",
                    paddingLeft: 0,
                    paddingRight: 10,
                  }}
                />
                <Tab
                  label="エースカード"
                  value="2"
                  style={{
                    fontWeight: "bold",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                />
                <Tab
                  label="カード採用率"
                  value="3"
                  style={{
                    fontWeight: "bold",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                />
                <Tab
                  label="デッキ検索"
                  value="4"
                  style={{
                    fontWeight: "bold",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                />
              </TabList>
            </Box>

            <TabPanel value="1" sx={{ pl: 0, pr: 0 }}>
              <DeckListTab
                items={deckListData.data}
                loading={loading}
                error={deckListError}
                setTabValue={setTabValue}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ pl: 0, pr: 0 }}>
              <AceCardTab
                items={aceCardData.data}
                loading={loading}
                error={aceCardError}
                setTabValue={setTabValue}
              />
            </TabPanel>
            <TabPanel value="3" sx={{ pl: 0, pr: 0 }}>
              <CardUsageRateTab
                cardName={cardName}
                setCardName={setCardName}
                currentCardName={currentCardName}
                setCurrentCardName={setCurrentCardName}
                items={cardUsageRateData.data}
                loading={loading}
                error={cardUsageRateError}
                fetchByCardName={fetchByCardName}
                setTabValue={setTabValue}
              />
            </TabPanel>
            <TabPanel value="4" sx={{ pl: 0, pr: 0 }}>
              <SearchDeckTab
                startDate={startDate}
                endDate={endDate}
                dateRangeError={dateRangeError}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Layout>
    </SearchDeckTabProvider>
  );
}
