import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";

function DisplayQuery({ index, query, handleUpdateQuery, handleDeleteQuery }) {
  const [newCard, setNewCard] = useState("");
  const [cardOptions, setCardOptions] = useState([]);

  useEffect(() => {
    const fetchCardNames = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/cards?search=${encodeURIComponent(
            newCard
          )}`
        );
        const data = await response.json();
        setCardOptions(data);
      } catch (error) {
        console.error("Failed to fetch card names:", error);
      }
    };

    if (newCard.length > 0) {
      fetchCardNames();
    }
  }, [newCard]);

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} display="flex" alignItems="flex-end">
          <Autocomplete
            multiple
            options={cardOptions.map((option) => option.name)}
            onInputChange={(event, newValue) => setNewCard(newValue)}
            renderInput={(params) => (
              <TextField {...params} size="small" variant="standard" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            value={query["card_names"]}
            onChange={(event, value) =>
              handleUpdateQuery(index, "card_names", value)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row">
            <Box display="flex" alignItems="center" marginRight={1}>
              <Typography variant="body2" noWrap>
                合わせて
              </Typography>
            </Box>
            <FormControl fullWidth variant="standard">
              <Select
                value={query["card_num"]}
                onChange={(event) =>
                  handleUpdateQuery(index, "card_num", event.target.value)
                }
                fullWidth
              >
                {newCard.includes("基本") && newCard.includes("エネルギー")
                  ? Array.from({ length: 20 }, (_, i) => (
                      <MenuItem key={i} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    ))
                  : Array.from({ length: 4 }, (_, i) => (
                      <MenuItem key={i} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2">枚</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={4} display="flex" alignItems="flex-end">
          <FormControl fullWidth variant="standard">
            <Select
              labelId="condition-label-id"
              value={query["condition"]}
              onChange={(event) =>
                handleUpdateQuery(index, "condition", event.target.value)
              }
              fullWidth
            >
              <MenuItem value="以上">以上</MenuItem>
              <MenuItem value="ちょうど">ちょうど</MenuItem>
              <MenuItem value="以下">以下</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <IconButton
            onClick={() => {
              setNewCard("");
              setCardOptions([]);
              handleDeleteQuery(index);
            }}
            sx={{ paddingBottom: 0, paddingRight: 0 }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default function CardNameForm({
  queryList,
  setQueryList,
  handleUpdateQuery,
  handleDeleteQuery,
}) {
  return (
    <>
      <Box width="100%">
        <Typography variant="caption">カード名</Typography>
      </Box>
      <Grid container spacing={2}>
        {queryList.map((query, index) => (
          <>
            <Grid item xs={12}>
              <DisplayQuery
                key={index}
                index={index}
                query={query}
                handleUpdateQuery={handleUpdateQuery}
                handleDeleteQuery={handleDeleteQuery}
              />
            </Grid>
            {index < queryList.length - 1 && (
              <Grid item xs={12}>
                <Typography
                  sx={{ marginY: -1, justifyContent: "center" }}
                  align="center"
                >
                  AND
                </Typography>
              </Grid>
            )}
          </>
        ))}
      </Grid>
      <IconButton
        color="success"
        onClick={() =>
          setQueryList([
            ...queryList,
            { card_names: [], card_num: 1, condition: "以上" },
          ])
        }
      >
        <AddCircleIcon />
      </IconButton>
    </>
  );
}
